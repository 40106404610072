<template>
<b-row class="h-100">
    <b-colxx xxs="12" md="10" class="mx-auto my-auto">
        <b-card class="auth-card" no-body>
            <div class="position-relative image-side">
                <p class="text-white h2">{{ $t('dashboards.magic-is-in-the-details') }}</p>
                <p class="white mb-0">
                    Favor de ingresar la nueva contraseña
                </p>
            </div>
            <div class="form-side">
                <router-link tag="a" to="/">
                    <img class="customLogo loginLogo" src="/static/img/logo-sole.svg">
                </router-link>
                <h6 class="mb-4">{{ $t('Reestablecer contraseña')}}</h6>

                <b-form @submit.prevent="formSubmit" class="av-tooltip tooltip-label-bottom">
                    <b-form-group :label="$t('Nueva contraseña')" class="has-float-label mb-4">
                        <b-form-input type="password" v-model="$v.form.password.$model" :state="!$v.form.password.$error" />
                        <b-form-invalid-feedback v-if="!$v.form.password.required">Favor de ingresar la nueva contraseña</b-form-invalid-feedback>
                        <b-form-invalid-feedback v-else-if="!$v.form.password.minLength || !$v.form.password.maxLength">La contraseña debe tener al menos 6 caracteres</b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group :label="$t('Confirmar nueva contraseña')" class="has-float-label mb-4">
                        <b-form-input type="password" v-model="$v.form.passwordAgain.$model" :state="!$v.form.passwordAgain.$error" />
                        <b-form-invalid-feedback v-if="!$v.form.passwordAgain.required">Favor de ingresar la nueva contraseña de nuevo</b-form-invalid-feedback>
                        <b-form-invalid-feedback v-else-if="!$v.form.passwordAgain.sameAsPassword">Las contraseñas no coinciden</b-form-invalid-feedback>

                    </b-form-group>

                    <div class="d-flex justify-content-between align-items-center">
                        <b-button type="submit" variant="primary" size="lg" :disabled="processing" :class="{'btn-multiple-state btn-shadow': true,
                    'show-spinner': processing,
                    'show-success': !processing && loginError===false,
                    'show-fail': !processing && loginError }">
                            <span class="spinner d-inline-block">
                                <span class="bounce1"></span>
                                <span class="bounce2"></span>
                                <span class="bounce3"></span>
                            </span>
                            <span class="icon success">
                                <i class="simple-icon-check"></i>
                            </span>
                            <span class="icon fail">
                                <i class="simple-icon-exclamation"></i>
                            </span>
                            <span class="label">{{ $t('Reestablecer contraseña') }}</span>
                        </b-button>
                    </div>
                </b-form>
            </div>
        </b-card>
    </b-colxx>
</b-row>
</template>

<script>
import {
    mapGetters,
    mapActions
} from "vuex";
import {
    validationMixin
} from "vuelidate";
const {
    required,
    maxLength,
    minLength,
    email,
    sameAs
} = require("vuelidate/lib/validators");

export default {
    data() {
        return {
            form: {
                codigo:"",
                password: "",
                passwordAgain: ""
            },
        };
    },
    mixins: [validationMixin],
    validations: {
        form: {
            password: {
                required,
                maxLength: maxLength(16),
                minLength: minLength(4)
            },
            passwordAgain: {
                required,
                sameAsPassword: sameAs('password')

            },
        }
    },
    computed: {
        ...mapGetters(["currentUser", "processing", "loginError", "resetPasswordSuccess"])
    },
    methods: {

        ...mapActions(["create"]),
       
        resetPassword: function(){
            var instance = $this;
            instance.$parent.toggleLoader("Enviando datos");

            let payload = {
                endpoint: "/api/olvide-contrasena/actualizar",
                data: instance.form
            }

            this.create(payload).then(function(response){
                if(response.status == 200){
                    instance.sent = true;
                    instance.$toasted.success("Envio exitoso");
                }else{
                    instance.$toasted.error("Ocurrió un error en la solicitud")
                }
            }).catch(error => {
                instance.$toasted.error(error);
            });
        }
    },
    watch: {
        loginError(val) {
            if (val != null) {
                this.$notify("error", "Reset Password Error", val, {
                    duration: 3000,
                    permanent: false
                });

            }
        },
        resetPasswordSuccess(val) {
            if (val) {
                this.$notify(
                    "success",
                    "Reset Password Success",
                    "Reset password success", {
                        duration: 3000,
                        permanent: false
                    }
                );
            }
        }
    }
};
</script>
